import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../../shared/Header';
import { Stack } from '@mui/system';
import { Button, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Interview from '../Interview';
import { useLocation, useNavigate } from 'react-router';
import MockInterview from './MockInterview';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

const MockInterviewDashboard = () => {
	const [interviews, setInterviews] = useState([]);
	const [profileImage, setProfileImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const { state } = useLocation();
	const navigate = useNavigate();
	const fullName = `${firstName} ${lastName}`;

	const getAllInterviews = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/student/mock-interviews`,
				{ search: searchTerm },
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			setInterviews(data.data);
			if (data.success) {
				console.log(data);
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		getAllInterviews();
	}, [searchTerm]);

	useEffect(() => {
		getDetails();
	}, []);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/details`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			console.log(data, 'data student');
			setFirstName(data.studentDetails.first_name);
			setLastName(data.studentDetails.last_name);
			// setEmail(data.studentDetails.email);
			setProfileImage(data.studentDetails.profile_image);
			// setTotalCompletedInterviews(data.studentDetails.total_completed_interviews);
			// setTotalStartedInterviews(data.studentDetails.total_started_interviews);
			//setFilteredinterviews(data.data);
			console.log(data);
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	const filteredInterviews = interviews.filter((interview) => {
		const searchMatch = interview.role.toLowerCase().includes(searchTerm.toLowerCase());

		return searchMatch;
	});

	return (
		<>
			<Toaster />
			<Header fullName={fullName} />
			<Stack
				p={{ sm: 5, xs: 3 }}
				spacing={{ sm: 5, xs: 3 }}
				style={{ backgroundColor: '#ecf7fa' }}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '0 20px',
					}}
				>
					<Typography fontWeight={'bold'} fontSize={'1.3rem'}>
						All Mock-Interviews
					</Typography>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '25px',
							width: 'auto',
						}}
					>
						<TextField
							variant="outlined"
							label="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
								),
							}}
						/>
						<button
							onClick={() =>
								navigate('/student/mock-interview/all-report', {
									state: {
										fullName: `${firstName} ${lastName}`,
									},
								})
							}
							style={{
								cursor: 'pointer',
								padding: '10px 16px',
								backgroundColor: '#224C84',
								borderRadius: '5px',
								color: '#fff',
								border: 'none',
								fontSize: '1rem',
								width: 'auto',
								transition: 'transform 0.3s ease',
								whiteSpace: 'nowrap',
							}}
							onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.04)')}
							onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
						>
							<Typography fontWeight={'semibold'} fontSize={'1.3rem'}>
								View Interview Reports
							</Typography>
						</button>
					</div>
				</div>
				<Stack
					direction={'row'}
					useFlexGap
					flexWrap={'wrap'}
					spacing={5}
					// columnGap={5}
					// rowGap={2}
					// justifyContent={"space-between"}
					alignItems={'center'}
				>
					{loading && (
						<Stack
							p={{ sm: 3, xs: 2 }}
							bgcolor={'#fff'}
							spacing={2}
							width={{ sm: '400px', xs: '100%' }}
							height={'auto'}
							borderRadius={'20px'}
							// border={"2px solid #cccccc"}
							boxShadow={'8px 6px 12px 0 rgba(13,31,61,.04)'}
						>
							<Toaster />
							<Typography fontWeight={900} fontSize={'1.5rem'}>
								<Skeleton
									variant="text"
									width={'250px'}
									// width={{ sm: "300px", xs: "250px" }}
								/>
							</Typography>
							<Typography fontSize={'1rem'} color={'#cccccc'}>
								<Skeleton variant="text" width={'200px'} />
							</Typography>
							<Skeleton variant="text" />
							<Skeleton variant="text" />
							<Skeleton variant="text" />
							<Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
								<Stack
									direction={'row'}
									spacing={1}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<SignalCellularAltIcon
										style={{
											color: '#cccccc',
										}}
									/>
									<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
										<Skeleton variant="text" width={'15px'} />
									</Typography>
								</Stack>
								<Stack
									direction={'row'}
									spacing={1}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<LiveHelpIcon
										style={{
											color: '#cccccc',
										}}
									/>
									<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
										<Skeleton variant="text" width={'15px'} />{' '}
									</Typography>
								</Stack>
							</Stack>
							<Skeleton variant="button" width={'100px'} />
						</Stack>
					)}
					{!loading && filteredInterviews?.length === 0 && (
						<Typography>No interviews available for you.</Typography>
					)}
					{!loading &&
						filteredInterviews?.map((interview) => (
							<MockInterview
								interviewDescription={interview?.description}
								key={interview._id}
								interview_id={interview._id}
								role={interview.role}
								industry={interview.industry}
								interview_level={interview.interview_level}
								questions={interview.questions}
								fullName={fullName}
							/>
						))}
				</Stack>
			</Stack>
		</>
	);
};

export default MockInterviewDashboard;
