import React, { useState, useEffect } from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { TextField } from '@mui/material';

const CustomToolbar = ({ onFilterChange }) => {
	const [filterValue, setFilterValue] = useState(localStorage.getItem('filterValue') || '');

	useEffect(() => {
		localStorage.setItem('filterValue', filterValue);
	}, [filterValue]);

	const handleFilterValueChange = (event) => {
		const value = event.target.value;
		setFilterValue(value);
		onFilterChange(value);
	};

	return (
		<GridToolbarContainer>
			<TextField
				label="Search"
				value={filterValue}
				onChange={handleFilterValueChange}
				variant="outlined"
				size="small"
				style={{
					margin: '5px',
				}}
			/>
			<GridToolbarExport />
		</GridToolbarContainer>
	);
};

export default CustomToolbar;
