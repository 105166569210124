import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, TextField, InputAdornment, Autocomplete, Button } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import { Header } from '../../shared/Header';

const CompleteStudentProfile = () => {
	const { state } = useLocation();
	const { first_name, last_name, email, profile_image } = state;
	const [firstName, setFirstName] = useState(first_name);
	const [lastName, setLastName] = useState(last_name);
	const [mail, setMail] = useState(email);
	const [profileImage, setProfileImage] = useState(profile_image);
	const [phone, setPhone] = useState('');
	const [city, setCity] = useState('');
	const [userState, setUserState] = useState('');
	const [pin, setPin] = useState('');
	const [degree, setDegree] = useState('');
	const [college, setCollege] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const handleSubmit = async () => {
		if (phone.length !== 10) {
			toast.error('Phone number should be of 10 digits');
			return;
		}
		if (pin.length !== 6) {
			toast.error('Pin code should be of 6 digits');
			return;
		}
		if (!userState) {
			toast.error('Please select a state');
			return;
		}
		if (!degree) {
			toast.error('Please select a degree');
			return;
		}
		if (!college) {
			toast.error('Please enter college name');
			return;
		}
		setLoading(true);
		const { data } = await axios.post(
			`${API_URL}/labs/student/mock-interview/complete-profile`,

			{
				first_name: firstName,
				last_name: lastName,
				email: mail,
				profile_image: profileImage,
				phone_number: phone,
				city,
				state: userState,
				pin_code: pin,
				degree,
				college,
			},
			{
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			}
		);
		console.log(data);
		if (data.success) {
			toast.success(data.message);
			navigate('/student/mock-interview/dashboard');
		} else {
			toast.error(data.message);
		}
		setLoading(false);
	};
	const degrees = [
		{ label: 'B.Tech', id: 1 },
		{ label: 'B.E', id: 2 },
		{ label: 'B.Sc', id: 3 },
		{ label: 'B.Com', id: 4 },
		{ label: 'B.A', id: 5 },
		{ label: 'M.Tech', id: 6 },
		{ label: 'M.E', id: 7 },
		{ label: 'M.Sc', id: 8 },
		{ label: 'M.Com', id: 9 },
		{ label: 'M.A', id: 10 },
		{ label: 'Ph.D', id: 11 },
		{ label: 'Diploma', id: 12 },
		{ label: 'B.Pharma', id: 13 },
		{ label: 'M.Pharma', id: 14 },
		{ label: 'BBA', id: 15 },
		{ label: 'MBA', id: 16 },
		{ label: 'BCA', id: 17 },
		{ label: 'MCA', id: 18 },
		{ label: 'B.Ed', id: 19 },
		{ label: 'M.Ed', id: 20 },
		{ label: 'B.Arch', id: 21 },
		{ label: 'M.Arch', id: 22 },
		{ label: 'startup', id: 23 },
	];
	const states = [
		{ label: 'Andhra Pradesh', id: 1 },
		{ label: 'Arunachal Pradesh', id: 2 },
		{ label: 'Assam', id: 3 },
		{ label: 'Bihar', id: 4 },
		{ label: 'Chhattisgarh', id: 5 },
		{ label: 'Goa', id: 6 },
		{ label: 'Gujarat', id: 7 },
		{ label: 'Haryana', id: 8 },
		{ label: 'Himachal Pradesh', id: 9 },
		{ label: 'Jammu and Kashmir', id: 10 },
		{ label: 'Jharkhand', id: 11 },
		{ label: 'Karnataka', id: 12 },
		{ label: 'Kerala', id: 13 },
		{ label: 'Madhya Pradesh', id: 14 },
		{ label: 'Maharashtra', id: 15 },
		{ label: 'Manipur', id: 16 },
		{ label: 'Meghalaya', id: 17 },
		{ label: 'Mizoram', id: 18 },
		{ label: 'Nagaland', id: 19 },
		{ label: 'Odisha', id: 20 },
		{ label: 'Punjab', id: 21 },
		{ label: 'Rajasthan', id: 22 },
		{ label: 'Sikkim', id: 23 },
		{ label: 'Tamil Nadu', id: 24 },
		{ label: 'Telangana', id: 25 },
		{ label: 'Tripura', id: 26 },
		{ label: 'Uttarakhand', id: 27 },
		{ label: 'Uttar Pradesh', id: 28 },
		{ label: 'West Bengal', id: 29 },
		{ label: 'Andaman and Nicobar Islands', id: 30 },
		{ label: 'Chandigarh', id: 31 },
		{ label: 'Dadra and Nagar Haveli', id: 32 },
		{ label: 'Daman and Diu', id: 33 },
		{ label: 'Delhi', id: 34 },
		{ label: 'Lakshadweep', id: 35 },
		{ label: 'Puducherry', id: 36 },
	];
	const colleges = [
		{ label: 'SVES', id: 1 },
		{ label: 'demo college', id: 2 },
	];

	return (
		<>
			<Header />
			<Toaster />
			<Stack
				pt={{ xs: '20vh', sm: '10vh' }}
				height={'80vh'}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Stack
					width={{ sm: '400px', xs: '350px' }}
					bgcolor={'#fff'}
					p={2}
					spacing={2}
					justifyContent={'center'}
					alignItems={'center'}
				>
					<Typography
						fontWeight={'bold'}
						fontSize={'1.1rem'}
						style={{ marginBottom: '15px' }}
					>
						Complete Your Profile
					</Typography>
					{/* <img
						src={profileImage}
						alt="Profile"
						style={{
							height: '100px',
							width: '100px',
							borderRadius: '50%',
						}}
					/> */}
					<Stack direction={'row'} spacing={2}>
						<TextField
							size="small"
							variant={'outlined'}
							value={firstName}
							label={'First Name'}
							disabled={loading}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<TextField
							size="small"
							variant={'outlined'}
							value={lastName}
							disabled={loading}
							label={'Last Name'}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</Stack>
					<TextField
						size="small"
						variant={'outlined'}
						value={mail}
						label={'Email'}
						disabled
						fullWidth
					/>
					<TextField
						size="small"
						variant={'outlined'}
						value={phone}
						label={'Phone'}
						onChange={(e) => setPhone(e.target.value)}
						fullWidth
						disabled={loading}
						InputProps={{
							startAdornment: <InputAdornment position="start">+91</InputAdornment>,
						}}
					/>
					<Stack direction={'row'} spacing={2}>
						<TextField
							size="small"
							variant={'outlined'}
							value={city}
							label={'City'}
							disabled={loading}
							onChange={(e) => setCity(e.target.value)}
						/>
						{/* create dropdown to choose state of india */}
						<TextField
							size="small"
							type="number"
							variant={'outlined'}
							value={pin}
							label={'Pin Code'}
							disabled={loading}
							inputProps={{
								maxLength: 6,
							}}
							onChange={(e) => setPin(e.target.value)}
						/>
					</Stack>
					<Autocomplete
						size="small"
						value={userState}
						disablePortal
						fullWidth
						disabled={loading}
						options={states}
						onChange={(e, value) => {
							if (value) setUserState(value.label);
							else setUserState('');
						}}
						renderInput={(params) => (
							// <TextField {...params} label="State" />
							<TextField
								size="small"
								variant={'outlined'}
								fullWidth
								{...params}
								label={'State'}
							/>
						)}
					/>
					<Autocomplete
						size="small"
						disablePortal
						fullWidth
						disabled={loading}
						options={degrees}
						onChange={(e, value) => {
							if (value) setDegree(value.label);
							else setDegree('');
						}}
						renderInput={(params) => (
							// <TextField {...params} label="Degree" />
							<TextField
								size="small"
								variant={'outlined'}
								fullWidth
								{...params}
								label={'Degree'}
							/>
						)}
					/>
					{/* <Autocomplete
						size="small"
						disablePortal
						fullWidth
						disabled={loading}
						options={colleges} */}
					{/* // renderInput={(params) => ( */}
					<TextField
						size="small"
						variant={'outlined'}
						fullWidth
						label={'College'}
						disabled={loading}
						onChange={(e) => setCollege(e.target.value)}
					/>
					<Button
						variant={'contained'}
						fullWidth
						disabled={loading}
						sx={{
							textTransform: 'none',
							backgroundColor: '#224C84',
							color: '#fff',
							'&:hover': {
								backgroundColor: '#224C84',
								color: '#fff',
							},
						}}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</Stack>
			</Stack>
		</>
	);
};

export default CompleteStudentProfile;
