import * as React from 'react';
import './header.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAdminContext } from '../../../global/AdminContext';
import { Avatar, Menu, MenuItem, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

const drawerWidth = 240;
const navItems = [
	{ name: 'Home', path: '/' },
	{ name: 'About', path: '/about' },
	{ name: 'Contact', path: '/contact' },
	{ name: 'Dashboard', path: '/admin' },
];

export const Header = (props) => {
	const [anchorEl, setAnchorEl] = React.useState();
	const { fullName, isPracticeInterview, role } = props;
	// const { headerText } = props;
	const currentUrl = window.location.href;

	const location = useLocation();
	const job_id = location.state?.job_id;

	const navigate = useNavigate();
	const { admin, logout } = useAdminContext();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const params = useParams();
	const [roles, setRoles] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => setOpen(false);
	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};
	const profile_image = sessionStorage.getItem('profile-image');
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		// sessionStorage.removeItem("admin");
		// sessionStorage.removeItem("profile-image");
		sessionStorage.clear();
		navigate('/');
	};

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
			<Typography variant="h6" sx={{ my: 2 }}>
				MUI
			</Typography>
			<Divider />
			<List>
				{navItems.map((item) => (
					<ListItem key={item.name} disablePadding>
						<ListItemButton sx={{ textAlign: 'center' }}>
							<ListItemText primary={item.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	const goToHome = () => {
		const isAdmin = sessionStorage.getItem('admin');
		const isMockInterview = sessionStorage.getItem('mock-interview');
		const isStudent = sessionStorage.getItem('student-auth-token');
		if (isAdmin) {
			navigate('/dashboard/jobslist/company');
		} else if (isMockInterview && isStudent) {
			navigate('/student/mock-interview/dashboard');
		} else if (isStudent && !isMockInterview) {
			navigate('/student/dashboard');
		} else {
			navigate('/');
		}
	};

	const handleExitBtn = () => {
		navigate('/login/candidate', {
			state: {
				type: 'candidate',
			},
		});
	};
	React.useEffect(() => {
		if (sessionStorage.getItem('admin') === null) return;
		const roles = JSON.parse(sessionStorage.getItem('admin')).roles || [];
		console.log(roles, 'roles');
		setRoles(roles);
	}, []);

	const [imageSrc, setImageSrc] = React.useState('');

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageSrc(e.target.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const jobRole = event.target.elements['job-role'].value;
		const jobDescription = event.target.elements['job-description'].value;
		// Handle form submission logic here
		console.log({ jobRole, jobDescription, imageSrc });
	};

	return (
		<Box sx={{ display: 'flex', height: '64px', backgroundColor: '#224C84' }}>
			<CssBaseline />
			<AppBar component="nav" bgcolor={'#224C84'}>
				<Toolbar
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						backgroundColor: '#224C84',
					}}
				>
					<Typography
						// variant="h6"
						// component="div"
						sx={{
							fontFamily: 'Gordita',
							// width: 'fit-content',
							cursor: 'pointer',
							flex: 1,
						}}
						onClick={goToHome}
						// onClick={() => navigate("/")}
					>
						<img src="/logo.png" alt="logo" height={'40px'} />
					</Typography>
					{isPracticeInterview && (
						<div style={{ display: 'flex' }}>
							<Button
								sx={{
									background: '#ffc107',
									color: 'white',
									textTransform: 'none',
								}}
								onClick={handleExitBtn}
							>
								Exit
							</Button>
						</div>
					)}
					{(fullName || profile_image) && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Gordita',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							Hi, {fullName}
						</Typography>
					)}

					{profile_image && (
						<>
							<Avatar
								sx={{ cursor: 'pointer', margin: '0 3rem' }}
								onClick={handleMenu}
								src={profile_image}
							/>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={handleLogout}>Logout</MenuItem>
							</Menu>
						</>
					)}

					{admin.exists && (
						<>
							{roles.includes('aihyr-admin') &&
								!currentUrl.match(/\/candidate\/report-v\d+\//) &&
								!currentUrl.includes('collegestudentReport') && (
									<>
										<Button
											sx={{
												color: '#fff',
												textTransform: 'none',
												textDecoration: 'underline',
											}}
											onClick={() => navigate('/dashboard/jobslist/company')}
										>
											Jobs
										</Button>
										<Button
											sx={{
												color: '#fff',
												textTransform: 'none',
												textDecoration: 'underline',
											}}
											onClick={() => navigate('/dashboard/jobs-companies')}
										>
											Companies
										</Button>
										<Button
											sx={{
												color: '#fff',
												textTransform: 'none',
												textDecoration: 'underline',
											}}
											onClick={() => navigate('/college-admin')}
										>
											College Interviews
										</Button>
										<Button
											sx={{
												color: '#fff',
												textTransform: 'none',
												textDecoration: 'underline',
											}}
											onClick={() => navigate('/dashboard/jobs-colleges')}
										>
											Colleges
										</Button>
										<Button
											sx={{
												color: '#fff',
												textTransform: 'none',
												textDecoration: 'underline',
											}}
											onClick={handleModalOpen}
										>
											Create Mock Interviews
										</Button>
									</>
								)}

							{(roles.includes('aihyr-admin') || roles.includes('super-admin')) &&
								!currentUrl.match(/\/candidate\/report-v\d+\//) &&
								!currentUrl.includes('collegestudentReport') && (
									<>
										<Button
											sx={{
												color: '#fff',
												textTransform: 'none',
												textDecoration: 'underline',
											}}
											onClick={() => navigate('/jobdescription/check')}
										>
											Create Job Posting
										</Button>
									</>
								)}

							{roles.includes('aihyr-admin') &&
								(currentUrl.match(/\/candidate\/report-v\d+\//) ||
									currentUrl.includes('collegestudentReport')) && (
									<>
										<Typography variant="h6" fontWeight="semibold">
											Dashboard -{' '}
											<span
												onClick={() => {
													navigate(`/job/details/${job_id}`);
												}}
												style={{
													cursor: 'pointer',
												}}
											>
												{role}
											</span>
										</Typography>
									</>
								)}

							{roles.includes('aihyr-admin') && (
								<>
									<Avatar
										sx={{ cursor: 'pointer', margin: '0 3rem' }}
										onClick={handleMenu}
									>
										{admin.name[0]}
									</Avatar>
									<Menu
										id="menu-appbar"
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorEl)}
										onClose={handleClose}
									>
										<MenuItem onClick={handleClose}>My Account</MenuItem>
										<MenuItem onClick={handleLogout}>Logout</MenuItem>
									</Menu>
								</>
							)}
						</>
					)}
				</Toolbar>
			</AppBar>
			<Box component="nav">
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</Box>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleModalClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography id="transition-modal-title" variant="h6" component="h2">
							Add Mock Interview
						</Typography>
						<form onSubmit={handleSubmit}>
							<TextField
								id="job-role"
								label="Job Role"
								variant="outlined"
								fullWidth
								margin="normal"
								required
							/>
							<TextField
								id="job-description"
								label="Job Description"
								variant="outlined"
								fullWidth
								margin="normal"
								multiline
								rows={4}
								required
							/>
							<Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
								<Avatar
									alt="Job Role Image"
									src={imageSrc}
									sx={{ width: 56, height: 56, mr: 2 }}
								/>
								<Button variant="outlined" component="label">
									Upload Image
									<input
										type="file"
										hidden
										accept="image/*"
										onChange={handleImageChange}
									/>
								</Button>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
								<Button type="submit" variant="contained" color="primary">
									Submit
								</Button>
							</Box>
						</form>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};
